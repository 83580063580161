var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      staticClass: "popover-bubble",
      class: _vm.adaptiveSize ? "popover-bubble-adaptive" : "",
      attrs: {
        placement: "bottom-start",
        "popper-class": "popper-bubble",
        width: "auto",
        disabled: !_vm.enableTooltip,
        "visible-arrow": _vm.visibleArrow,
        trigger: "hover",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "bubble",
          class: [
            _vm.isSelect ? "select" : "",
            _vm.type ? `bubble__${_vm.type}` : "",
            _vm.disable ? "disable" : "",
            _vm.active ? "active" : "",
            _vm.colorfulFont ? `bubble-color__${_vm.type}` : "",
            _vm.size ? `bubble-size__${_vm.size}` : "",
          ],
          attrs: { slot: "reference" },
          on: {
            click: function ($event) {
              return _vm.handleClick($event)
            },
          },
          slot: "reference",
        },
        [
          _vm.$slots.default
            ? _c(
                "span",
                { staticClass: "bubble-label" },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
          _vm.enableCount
            ? _c("span", { staticClass: "bubble-count" }, [
                _vm._v("\n            " + _vm._s(_vm.count) + "\n        "),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "span",
        { staticClass: "bubble-tooltip" },
        [_vm._t("tooltipContent")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }