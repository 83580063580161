<template>
    <el-popover
        placement="bottom-start"
        class="popover-bubble"
        :class="adaptiveSize ? 'popover-bubble-adaptive' : ''"
        popper-class="popper-bubble"
        width="auto"
        :disabled="!enableTooltip"
        :visible-arrow="visibleArrow"
        trigger="hover">
        <div
            slot="reference"
            class="bubble"
            :class="[
                isSelect ? 'select' : '',
                type ? `bubble__${type}` : '',
                disable ? 'disable' : '',
                active ? 'active' : '',
                colorfulFont ? `bubble-color__${type}` : '',
                size ? `bubble-size__${size}` : ''
                ]"
            @click="handleClick($event)">
            <span
                v-if="$slots.default"
                class="bubble-label">
                <slot></slot>
            </span>
            <span
                class="bubble-count"
                v-if="enableCount">
                {{ count }}
            </span>
        </div>
        <span class="bubble-tooltip">
            <slot name="tooltipContent">

            </slot>
        </span>
    </el-popover>
</template>

<script>
    export default {
        name: "bubble",
        data(){
            return {
                select: false,
                visibleArrow: false,
                // tooltipIsShow: false,
            }
        },
        props: {
            count: {
                default: '',
                type: [Number,String]
            },
            isSelect: {
                default: false,
                type: Boolean
            },
            type:{
                default: 'primary',
                type: String,
            },
            hashCode: {
                default: '',
                type: String,
            },
            group: {
                default: '',
                type: String,
            },
            enableTooltip: {
                default: false,
                type: Boolean,
            },
            enableCount: {
                default: true,
                type: Boolean,
            },
            disable:{
                default: false,
                type: Boolean,
            },
            active: {
                default: true,
                type: Boolean
            },
            size: {
                default: 'medium',
                type: String
            },
            //是否自适应字体大小展示
            adaptiveSize: {
                default: false,
                type: Boolean
            },
            // 是否展示为带颜色的字
            colorfulFont: {
                default: false,
                type: Boolean
            }
        },
        created (){
            // this.tooltipIsShow = this.enableTooltip;
        },
        methods:{
            handleClick (e){
                if(!this.disable){
                    return this.$emit('click', this.group, this.hashCode, !this.isSelect);
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .popover-bubble{
        margin-right: 10px;

        &:last-child{
            margin-right: 0;
        }
    }

    .bubble{
        display: inline-block;
        padding: 0 10px;
        height: 28px;
        max-height: none;
        line-height: 28px;
        border-radius: 16px;
        color: #666;
        transition: .3s;
        user-select: none;

        &:focus,&:active,&:visited{
            outline: none;
        }

        .bubble-count{
            margin-left: 6px;
        }

        &__primary{
            background-color: rgba($primary , .1);
        }

        &__red{
            background-color: rgba($red, .1);
        }

        &__blue{
            background-color: rgba($blue, .1);
        }

        &__orange{
            background-color: rgba($orange, .1);
        }

        &__orange-dark{
            background-color: rgba($orange-dark, .1);
        }

        &-color{
            &__primary{
                color: $primary;
            }

            &__red{
                color: $red;
            }

            &__blue{
                color: $blue;
            }

            &__orange{
                color: $orange;
            }

            &__orange-dark{
                color: $orange-dark;
            }
        }

        &.active{
            cursor: pointer;

            &:hover,&.select{
                color: #fff;
            }

            &.bubble__primary{
                &:hover,&.select{
                    background-color: $primary;
                }
            }

            &.bubble__red{
                &:hover,&.select{
                    background-color: $red;
                }
            }

            &.bubble__blue{
                &:hover,&.select{
                    background-color: $blue;
                }
            }

            &.bubble__orange{
                &:hover,&.select{
                    background-color: $orange;
                }
            }

            &.bubble__orange-dark{
                &:hover,&.select{
                    background-color: $orange-dark;
                }
            }
        }

        &.disable{
            cursor: not-allowed;
        }

        &-size__small{
            height: 20px;
            line-height: 20px;
            border-radius: 10px;
            font-size: 12px;
        }

        &-size__medium {
            font-size: 14px;
        }
    }

    .bubble-tooltip{
        color: #666;
    }

@media screen and (max-width: 1366px){
    .popover-bubble-adaptive {
        font-size: 12px;
    }
}
</style>

<style lang="scss">
    .popper-bubble{
        padding: 10px 20px !important;
    }
</style>
